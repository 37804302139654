<template>
    <div class="surveyWarp">
        <fm-generate-form :data="jsonData" :remote="remoteFuncs" :value="editData" ref="generateForm">

            <template slot="linSpe" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.linSpe" to bind data -->
                <div class="linSpe"></div>
            </template>
            <template slot="cuninfo" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.cuninfo" to bind data -->
                <p class="title"><i></i>村情信息</p>
            </template>

            <template slot="provinces" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.provinces" to bind data -->
                <div class="ml">
                    <div >
                        <p>省市区县<i class="star"></i><b>：</b></p>
                        <van-field clickable

                                   readonly
                                   autosize
                                   input-align="right"
                                   v-model=" addressVal "
                                   label=""
                                   placeholder="省份、城市、区县"
                                   @click="addressShow = true"
                                   right-icon="arrow-down"></van-field>
                    </div>
                </div>

            </template>

            <template slot="country" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.country" to bind data -->
                <div class="ml">
                    <div>
                        <p>乡镇村<i class="star"></i><b>：</b></p>
                        <van-field clickable
                                   readonly
                                   autosize
                                   input-align="right"
                                   v-model="countryVal"
                                   label=""
                                   placeholder="乡镇村"
                                   @click="countryClick"
                                   right-icon="arrow-down"></van-field>
                    </div>

                </div>

            </template>

            <template slot="gps" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.gps" to bind data -->
                <div class="ml">
                    <div>
                        <p>GPS位置<b>：</b></p>
                        <van-field v-model="gps"
                                   clickable
                                   readonly
                                   @click="gpsClick"
                                   label=""

                                   right-icon="location-o"></van-field>
                    </div>
                </div>

            </template>


            <template slot="households" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.households" to bind data -->
                <div class="ml">
                    <div>
                        <p>户数<i class="star"></i><b>：</b></p>
                        <van-field v-model="households"
                                   label=""
                                   type="number"
                                   placeholder="请输入户数"
                        ></van-field>
                    </div>
                </div>
            </template>


            <template slot="imgArr" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.imgArr" to bind data -->
                <p class="title"><i></i>图片上传（1-3张）<i class="star"></i></p>
                <div class="card-upload">
                    <van-uploader v-model="fileList"
                                  accept="image"
                                  :max-count="3"
                                  :after-read="afterRead"
                                  :before-delete="deletePic" />
                </div>


            </template>
            <template slot="note" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.note" to bind data -->
                <p class="title"><i></i>备注</p>
                <van-field
                        class="note"
                        v-model="scope.model.note"
                        rows="1"
                        autosize
                        maxlength="100"
                        label=""
                        type="textarea"
                        placeholder="填写备注信息（5-100个字）"
                />

                <div class="linSpe"></div>

            </template>

            <template slot="buttInfoTitle" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.buttInfoTitle" to bind data -->
                <p class="title"><i></i>对接信息</p>
            </template>

            <template slot="bankInfo" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.bankInfo" to bind data -->
                <p class="title"><i></i>银行信息 <i class="star"></i></p>
                <div class="bankItems" v-for="(item,index) in bankArr" :key="index">
                    <p><label>{{item.bankName }} (距离{{item.bankDistance}}KM)</label><i class="close" @click="bankInfoDel(index)" name="close" ></i></p>

                </div>
                <div class="bankInfo" v-if="bankArr.length <5" @click="bankInfoInput">
                    <van-icon name="plus" /><label>添加银行网点</label>

                </div>


            </template>

        </fm-generate-form>
        <div class="BtnDiv">
            <div class="commitBtn" @click="handleSubmit">提交</div>
        </div>

        <van-popup v-model="addressShow"
                   position="bottom">
            <van-area :area-list="areaList"
                      @cancel="addressShow = false"
                      @confirm="areaConfirm"></van-area>
        </van-popup>
        <van-popup v-model="countryShow"
                   position="bottom">
            <van-picker show-toolbar
                        title="乡镇村"
                        :columns="countryList"
                        @cancel="countryShow = false"
                        @confirm="countryConfirm"
                        visible-item-count='5' />
        </van-popup>
        <van-popup v-model="mapshow"
                   v-if=" mapshow"
                   position="bottom">
            <div class="mapDiv">
                <div class="close">
                    <i @click="closeMapClick">
                        <van-icon name="cross" />
                    </i>
                </div>
                <el-amap ref="map" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin" :events="events" class="amap-demo">
                </el-amap>
            </div>

        </van-popup>
        <van-dialog  @confirm="bankInfoSave" v-model="isShowBankInput" title="添加银行网点" show-cancel-button>
           <el-form>
               <van-field v-model="bankName"
                          label="银行网点"
                          placeholder="请输入银行网点名称"
               ></van-field>
               <van-field v-model="bankDistance"

                          label="银行网点距离(KM)"
                          placeholder="请输入距离"
               ></van-field>
           </el-form>
        </van-dialog>
    </div>
</template>

<script>
    import { AMapManager,lazyAMapApiLoaderInstance} from 'vue-amap';
    let amapManager = new AMapManager()
    import areaList from "@/utils/area"
    import {
        createProbeTask,
        getAreaInfoCountyDict, getProbeTaskData, getSubTaskTemp, saveProbeTask, saveSubTaskDetail,
        uploadZipAndAlreadyImg
    } from "../../utils/api";
    import common from "../../utils/common";
    import JsZip from "jszip";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        data () {
            let self = this
            return {
                jsonData: {
                    "list":[],
                    config:{}
                },

                editData: {
                },
                remoteFuncs: {

                    funcGetToken (resolve) {
                        // 图片上传 img
                        // Call callback function once get the token
                        // resolve(token)
                    },

                },

                addressShow: false,
                areaList,
                provinces:'',
                addressVal:'',
                countryShow:false,
                countryList:[],
                countryVal:'' ,
                amapManager,
                zoom: 12,
                center: [],
                events: {
                    init: (o) => {
                        o.getCity(result => {
                        })
                    },
                    'moveend': () => {
                    },
                    'zoomchange': () => {
                    },
                    'click': (e) => {

                        let { lng, lat } = e.lnglat

                        self.center = [lng, lat]

                        let geocoder = new AMap.Geocoder({
                            radius: 1000,
                            extensions: 'all'
                        })
                        geocoder.getAddress([lng, lat], function (status, result) {

                            if (status === 'complete' && result.info === 'OK') {
                                if (result && result.regeocode) {

                                    self.gps = result.regeocode.addressComponent.township + result.regeocode.addressComponent.street + result.regeocode.addressComponent.streetNumber

                                    self.mapshow = false
                                    self.$nextTick()
                                }
                            }
                        })
                    }
                },
                plugin: ['ToolBar', {
                    pName: 'MapType',
                    defaultType: 0,
                    events: {
                        init(o) {
                            console.log(o);

                        }
                    }
                }],
                mapshow:false,
                gps:'',
                bankArr:[],
                isShowBankInput:false,
                bankName:'',
                bankDistance:'',
                fileList:[],
                uploadZipImgList:[],
                fileImgUrls:[],
                households:'',
                isNeedPic:false,
                taskCode:'probe',
                subTaskCode:'',
                taskId:'',
                savePara:{
                    taskDesc:'',
                    areaCode:'',
                    taskNo:'',
                    hasSubmit:false,
                },
                saveSubPara:{
                    id:'',
                    hasFinished:1,
                    taskDetail:''
                },
                provincesAreaCode:'',
                saveAreaCode:'',//保存下来编辑时候有用
                windowHeight:0
            }
        },
        filters:{

        },
        mounted() {
            this.subTaskCode = this.$route.query.subTaskCode
            this.taskId = this.$route.query.taskId
            if (this.$route.query.taskNo){
                this.savePara.taskNo = this.$route.query.taskNo

            }else {
                createProbeTask({}).then(res=>{
                    this.savePara.taskNo = res.data.data
                })
            }


            let p = {
                taskCode:this.taskCode,
                subTaskCode:this.subTaskCode
            }
            getSubTaskTemp(p).then(res=>{
                if (res.code === 200 && res.data.businessCode === 200){
                    this.jsonData = res.data.data
                }

            })
            let editDataP = {
                id:this.taskId
            }
            getProbeTaskData(editDataP).then(res=>{
                if (res.data.data){
                    this.editData = res.data.data
                    this.addressVal = this.editData.provinces?this.editData.provinces:''
                    this.countryVal = this.editData.country?this.editData.country:""
                    this.households = this.editData.households?this.editData.households:''
                    this.fileImgUrls = this.editData.imgArr?this.editData.imgArr:[]
                    this.saveAreaCode = this.editData.saveAreaCode?this.editData.saveAreaCode:''
                    this.gps = this.editData.gps?this.editData.gps:''
                    this.center = this.editData.center?this.editData.center:[116.403419,39.924074]
                    this.handlePic(this.fileImgUrls )
                    this.bankArr =this.editData.bankInfo? JSON.parse(this.editData.bankInfo):[]
                    this.provincesAreaCode = this.editData.provincesAreaCode?this.editData.provincesAreaCode:''
                    console.log("初始化:",this.uploadZipImgList)
                }
            })
            this.inputKey()
            uwStatisticAction('/surveyInput','走访调研内容输入')
            console.log("1:",this.uploadZipImgList)
        },
        methods: {
            inputKey(){
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
                if (!isIOS){
                   return
                }
                let that = this
                this.$nextTick(() => {
                    setTimeout(function () {

                        document.querySelectorAll("input").forEach(item => {

                            item.onblur = function () {

                                that.temporaryRepair()
                            }
                        });
                    },1000)


                })
            },
            temporaryRepair() {
                let that = this
                let windowFocusHeight = window.innerHeight
                if (that.windowHeight == windowFocusHeight) {

                    return
                }
                console.log('修复');
                let currentPosition;
                let speed = 1; //页面滚动距离
                currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
                currentPosition -= speed;
                window.scrollTo(0, currentPosition); //页面向上滚动
                currentPosition += speed; //speed变量
                window.scrollTo(0, currentPosition); //页面向下滚动
            },
            areaConfirm (val) {
                this.addressShow = false

                let code  = ''
                for (let obj of val) {
                    code += obj.name
                }
                this.addressVal = code
                this.provincesAreaCode = val[2].code
                  this.getAreaInfoCounty(this.provincesAreaCode)
                this.countryVal  = ''
                this.gps = ''
            },
            countryClick () {
                if (!this.addressVal) {
                    this.$toast('请先选择省市区县')
                    return
                }
                if (this.countryList.length === 0){
                    this.getAreaInfoCounty(this.provincesAreaCode)
                }
                this.countryShow = true
            },
            getAreaInfoCounty (countyNo) {
                this.countryList = []
                getAreaInfoCountyDict(countyNo).then((res) => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        this.countryListAll = data.townList
                        for (let obj of this.countryListAll) {
                            obj.children = obj.county
                            for (let o of obj.children) {
                                o.text = o.name
                            }
                            obj.text = obj.name
                            this.countryList.push(obj)
                        }
                    }
                })
            },
            countryConfirm (val, i) {
                this.countryShow = false
                this.countryVal = val.toString()

                this.saveAreaCode = this.countryList[i[0]].county[i[1]].areaCode
                let self = this
               let lat = this.countryList[i[0]].county[i[1]].lat
                let lng = this.countryList[i[0]].county[i[1]].lng
                self.center = [lng,lat]
                let geocoder = new AMap.Geocoder({
                    radius: 1000,
                    extensions: 'all'
                })
                geocoder.getAddress(self.center, function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        if (result && result.regeocode) {
                            self.gps = result.regeocode.addressComponent.township + result.regeocode.addressComponent.street + result.regeocode.addressComponent.streetNumber

                            self.$nextTick()
                        }
                    }
                })
            },
            gpsClick () {
                if (!this.countryVal) {
                    this.$toast('请先选择乡镇村')
                    return
                }
                this.mapshow = true
            },
            closeMapClick(){
                this.mapshow = false
            },
            bankInfoInput(){
                this.bankName = ''
                this.bankDistance = ''
                this.isShowBankInput = true

            },
            bankInfoSave(){
                if (!this.bankName){
                    this.$toast("请输入银行网点")
                    this.isShowBankInput = true
                    return
                }
                if (!this.bankDistance){
                    this.$toast("请输入银行网点距离")
                    this.isShowBankInput = true
                    return
                }
                this.bankArr.push({
                    bankName:this.bankName,
                    bankDistance:this.bankDistance
                })

            },
            afterRead (file, detail) {

                // 大于15M
                if (file.file.size > 15 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于15M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 100 * 1024) {

                    let namearr = file.file.name.split('.')

                    let name = '.' + namearr[namearr.length - 1]

                    _this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {

                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({ data: dataURL, name: name })

                        })
                    }
                }
            },
            deletePic (file, pic) {

                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.fileImgUrls.splice(pic.index,1)

                }else {
                    this.uploadZipImgList.splice(pic.index - this.fileImgUrls.length,1)

                }

            },
            bankInfoDel(index){
                this.bankArr.splice(index,1)
            },
            commitAction(){
                console.log("uploadZipImgList:",this.uploadZipImgList)
                if(this.uploadZipImgList.length > 0) {
                    console.log('有图片上传')
                    console.log(this.uploadZipImgList)
                    let self = this
                    let imgName = 'surveyInputImg'
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (var i = 0; i < this.uploadZipImgList.length; i++) {
                        img.file(imgName + '_' + i + this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",") + 1), {base64: true});
                    }
                    let json = {}
                    json[imgName] = self.fileImgUrls
                    let fileAleradyJson = JSON.stringify(json)
                    console.log("提交的网络图片：",json)
                    zip.generateAsync({type: "blob"}).then(function (content) {

                        uploadZipAndAlreadyImg(content, '1', fileAleradyJson).then(res => {
                            if (res.code == 200 && (res.data.businessCode === 200)) {

                                let arr = res.data.surveyInputImg

                                self.fileImgUrls = arr
                                self.saveAction()

                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')

                    });

                }else {
                    console.log('没有图片提交')
                    this.saveAction()

                }


            },

            checkInput(){
                let isFinished = true
                for (var i = 0 ;i < this.jsonData.list.length;i++){
                    if (this.jsonData.list[i].model === 'provinces'){
                        if (!this.addressVal){
                            this.$toast('请选择省市区')
                            isFinished = false
                            break
                        }

                    }  if (this.jsonData.list[i].model === 'country'){
                        if (!this.countryVal){
                            this.$toast('请选择乡村镇')
                            isFinished = false
                            break
                        }

                    } if (this.jsonData.list[i].model === 'households'){
                        if (!this.households){
                            this.$toast('请输入户数')
                            isFinished = false
                            break
                        }

                    }
                    if (this.jsonData.list[i].model === 'imgArr'){
                        this.isNeedPic = true
                        if (this.fileList.length === 0){
                            this.$toast('请上传图片')
                            isFinished = false
                            break
                        }
                    } if (this.jsonData.list[i].model === 'bankInfo'){
                        if (this.bankArr.length === 0){
                            this.$toast('请添加银行网点')
                            isFinished = false
                            break
                        }
                    }
                }
                return isFinished
            },
            saveAction(){
                let self = this
                this.$refs.generateForm.getData().then(data => {
                    self.editData = data
                    self.editData.provinces = self.addressVal
                    self.editData.country = self.countryVal
                    self.editData.households = self.households
                    self.editData.imgArr = self.fileImgUrls
                    self.editData.bankInfo = JSON.stringify(self.bankArr)
                    self.editData.provincesAreaCode = self.provincesAreaCode
                    self.editData.saveAreaCode = self.saveAreaCode
                    self.editData.gps = self.gps
                    self.editData.center = self.center
                    console.log("表单参数：",self.editData)
                    self.saveSubPara.id = this.taskId
                    self.saveSubPara.taskDetail = JSON.stringify(self.editData)
                    self.savePara.taskDesc = this.addressVal? this.addressVal + this.countryVal :undefined
                    self.savePara.areaCode = this.saveAreaCode?this.saveAreaCode:undefined
                    saveSubTaskDetail(self.saveSubPara).then(res=>{
                        if (res.code === 200 && res.data.businessCode === 200){
                            console.log("一个子任务：",self.$route.query.isOneSubTask )
                            if (self.$route.query.isOneSubTask && self.$route.query.isOneSubTask === 'y'){
                                self.savePara.hasSubmit= true
                                saveProbeTask(this.savePara).then(taskRes=>{
                                    if (taskRes.code === 200 && taskRes.data.businessCode === 200){
                                        self.$router.push({
                                            path:'/visitSurvey/visitSurveySuccess'
                                        })
                                    }


                                })

                            }else {
                                if ( self.savePara.areaCode && self.savePara.taskDesc){
                                    self.savePara.hasSubmit= false
                                    saveProbeTask(this.savePara).then(taskRes=>{
                                        if (taskRes.code === 200 && taskRes.data.businessCode === 200){
                                            self.$router.go(-1)
                                        }


                                    })
                                }else {
                                    self.$router.go(-1)
                                }


                            }

                        }
                    })
                }).catch(e => {
                    // data check failed
                })
            },
            handleSubmit () {
                let isFinished = this.checkInput()
                if (!isFinished){
                    return
                }
                if (this.isNeedPic){
                    this.commitAction()
                }else {
                    this.saveAction()
                }



            },
            handlePic(imgUrlArr){
                this.fileList = []
                for(let i= 0; i< imgUrlArr.length; i++){
                    if(process.env.NODE_ENV == 'production'){
                        let url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')

                        this.fileList.push({url:url})
                    }else{
                       let url = 'http://124.70.167.116:20081' + '/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                        // let url = window.location.origin + '/file/' + this.fileList[i] + '?Authorization=' + common.getItem('wtToken')
                        this.fileList.push({url:url})

                    }
                }
            }

        }
    }
</script>
<style lang="less" scoped>
    .surveyWarp{
        padding:  0 0 60px 0 ;
        .title{
            margin-left: -95px;
            font-size: 17px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            height: 48px;
            line-height: 48px;
            i{
                width: 2px;
                height: 15px;
                display: inline-block;
                background: #3F7C53;
                border-radius: 1px;
                margin-right: 6px;

            }
            .star{
                width: 8px;
                height: 7px;
                display: inline-block;
                vertical-align: middle;
                background: url("../../assets/img/common/star.png");
                background-size: 100% 100%;
                margin-left: 2px;

            }
        }
        .ml{
            margin-left: -95px;
            width: 345px;
            height:48px ;
            div{
                display: flex;
                p{
                    display:inline-block;
                    width: 100px;
                    height: 48px;
                    line-height: 48px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    b{
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
                label{
                    width: 100px;
                    display: inline-block;
                }
                .star{
                    width: 8px;
                    height: 7px;
                    display: inline-block;
                    vertical-align: middle;
                    background: url("../../assets/img/common/star.png");
                    background-size: 100% 100%;
                    margin-left: 2px;

                }
            }

        }
        .linSpe{
            margin-left: -110px;
            width: 375px;
            height:10px ;
            background:#f7f8f9;
        }
        .bankInfo{
            margin-top: 5px;
            margin-left: -95px;
            width: 345px;
            border-radius: 8px;
            border: 1px dashed #999999;
            text-align: center;
            padding: 12px;
            margin-bottom: 15px;
            label{
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            };

        }
        .bankItems{
            margin-top: 5px;
            margin-left: -95px;
            width: 345px;
            margin-bottom: 15px;
            height: 58px;
            line-height: 58px;
            border-radius: 8px;
            border: 1px solid #999999;
            p{
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                }
                .close{
                   width: 30px;
                    height: 30px;
                    display: inline-block;
                    background: url("../../assets/img/common/close.png");
                    background-size: 100% 100%;
                }
            }
        }
        .card-upload{
            margin-left: -110px;
            padding: 15px;
        }
    }
    .fm-style{
        background: #ffffff;
        padding: 0;
    }
    .el-form-item__label{
        text-align: left;
    }

    .mapDiv{
        width:100%;
        height: 670px;
        .close {
            text-align: right;
            margin-bottom: 14px;
            .van-icon {
                background: url("../../assets/img/close.png");
                background-size: 100% 100%;
                width: 24px;
                height: 24px;
            }

        }
    }
    .BtnDiv{
        width: 100%;
        background: #ffffff;
        padding: 15px;
        position: fixed;
        bottom: 0;
        left: 0;
        .commitBtn{
            width: 100%;
            background: #3F7C53;
            border-radius: 4px;
            text-align: center;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 10px;
            height: 49px;
            line-height: 49px;
        }


    }
</style>
<style lang="less">
    .surveyWarp .el-form-item__label{
        text-align: left;
        width: 125px !important;
        padding-left: 15px;
        height: 48px;
        line-height: 48px;

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .surveyWarp .van-field__control{
        text-align: right;
    }
    .surveyWarp .el-form-item{
        border-bottom: 1px solid #f7f8f9;
    }
    .surveyWarp  .el-form-item__content{
        margin-left: 110px !important; ;
    }
    .surveyWarp .van-cell,.van-field__control,van-cell__value ,.van-cell__value--alone ,.van-field__value {
        height: 48px !important;
        line-height: 48px !important;
        padding: 0;
    }
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin: 0;
    }
    .surveyWarp .el-input{
        width: calc(100% - 15px) !important;
        padding-right: 15px;
        height: 48px;
        text-align: right;
        line-height: 48px;
    }
    .surveyWarp .el-input .el-input__inner,.surveyWarp .van-field__control{
        padding: 0;
        text-align: right;
        border: none;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
   .surveyWarp input::-webkit-input-placeholder,.surveyWarp textarea::-webkit-input-placeholder{   /* 使用webkit内核的浏览器 */

       font-size: 15px;
       font-family: PingFangSC-Regular, PingFang SC;
       font-weight: 400;
       color: #999999;
    }
    .surveyWarp input:-moz-placeholder,.surveyWarp textarea:-moz-placeholder{    /* Firefox版本4-18 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .surveyWarp input::-moz-placeholder,.surveyWarp textarea::-moz-placeholder{    /* Firefox版本19+ */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .surveyWarp input:-ms-input-placeholder,.surveyWarp textarea:-ms-input-placeholder{   /* IE浏览器 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }

    .surveyWarp{
        .note {
            margin-left: -95px;
            width: 345px;
            height: auto !important;
            line-height: normal !important;
            min-height: 110px;

        }
        .note .van-field__control{

            text-align: left;
            line-height: normal !important;
            min-height: 100px;
            padding: 10px  0;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
        .van-dialog{

            border-radius: 8px;
        }
        .van-dialog__header{
            padding-top: 20px;
        }
        .van-dialog__content{
            padding: 0 15px;
            .van-field__label,.van-field__control{

                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

            }
            .van-field__label{

                width: 140px;
            }

        }
        .van-dialog__cancel .van-button__text{

            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #B5BBC9;


        }
        .van-dialog__confirm .van-button__text{

            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3F7C53;

        }
        .van-dialog{
            top: 28%;
        }
    }

</style>
